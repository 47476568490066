import { useSelector } from 'react-redux';
import { emptyList } from '@constants/empty';
import {
  getActiveUidsByType,
  getCurrentRunningSubscriptionDetail,
  getIsAnyBatchActiveSubscription
} from '@models/Me';
import {
  batchTypes,
  centreContentTypes,
  subscriptionTypes
} from '@constants/index';
import { storeSubscriptionTypes } from '@cont/StoreListing/constants';
import { getMe } from './auth';

export const getIsPlusEnrolled = (state) => getMe(state).isPlusEnrolled;

export const getMePlusSubscriptions = (state) =>
  getMe(state).plusSubscriptions || emptyList;

export const getMeLatestUserSubscriptions = (state) =>
  getMe(state).latestUserSubscriptions || emptyList;

export const getMeLatestUserSubscriptionsTimeline = (state) =>
  getMe(state).latestUserSubscriptionsTimeline ?? emptyList;

export const LatestActiveUserSubscription = () => {
  const getMeLatestActiveUserSubscriptions = (state) =>
    getMe(state).latestUserSubscriptions || emptyList;
  const UserActiveSubscription = useSelector(
    getMeLatestActiveUserSubscriptions
  );
  const LatestActiveSubscription = UserActiveSubscription?.find(
    (userSubscription) => userSubscription.time_remaining > 0
  );
  return LatestActiveSubscription;
};

export const getPlusSubscriptionUIDs = (state) =>
  getMe(state).plusSubscriptionUids || emptyList;

export const getPlusWithoutTrialSubscriptionUIDs = (state) =>
  getMe(state).plusWithoutTrialSubscriptionUids || emptyList;

export const getIconicSubscriptionUIDs = (state) =>
  getMe(state).iconicSubscriptionUIDs || emptyList;

export const getActiveOfflineSubscriptionUIDs = (state) =>
  getMe(state).activeOfflineSubscriptionUIDs || emptyList;

export const getLiteSubscriptionUIDs = (state) =>
  getMe(state).liteSubscriptionUids || emptyList;

export const getPrePaymentPlusSubscriptionUIDs = (state) =>
  getMe(state).prePaymentPlusSubscriptionUids;

export const getIsSubscribed = (state) =>
  getPlusSubscriptionUIDs(state).length > 0;

export const getIsSubscribedToPlusWithoutTrial = (state) =>
  getPlusWithoutTrialSubscriptionUIDs(state).length > 0;

export const getPlusSubscriptionInfo = (goalUID) => (state) =>
  getMePlusSubscriptions(state).find(
    ({ subscription }) => subscription?.value.uid === goalUID
  );

export const getPlusSubscriptionInfoLatest = (goalUID) => (state) =>
  getMeLatestUserSubscriptions(state).find(
    ({ subscription }) => subscription?.value.uid === goalUID
  );

export const getIsSubscribedToGoal = (goalUID) => (state) => {
  if (!goalUID) {
    return false;
  }
  return getActiveUidsByType(getMe(state)).includes(goalUID.toUpperCase());
};

const hasGoalSubscription = (
  subscribedBatchContentTypes,
  batchSkuDetails,
  contentTypeId,
  checkStoreSubscription
) => {
  const hasPlusSubscriptionData =
    subscribedBatchContentTypes.indexOf(centreContentTypes.PLUS.toString()) !==
    -1;
  const currentRunningSubscriptionDetail =
    hasPlusSubscriptionData && batchSkuDetails?.metaInfo?.latestUserSkusTypeWise
      ? getCurrentRunningSubscriptionDetail(
          batchSkuDetails?.metaInfo?.latestUserSkusTypeWise[116]
        )
      : null;

  if (
    checkStoreSubscription &&
    contentTypeId === storeSubscriptionTypes.BATCH &&
    subscribedBatchContentTypes?.indexOf(
      storeSubscriptionTypes.BATCH.toString()
    ) !== -1
  )
    return true;

  if (
    currentRunningSubscriptionDetail &&
    currentRunningSubscriptionDetail?.subscription
  )
    return checkStoreSubscription
      ? currentRunningSubscriptionDetail?.subscription?.type ===
          subscriptionTypes.PLUS
      : currentRunningSubscriptionDetail?.subscription?.type !==
          subscriptionTypes.LITE;
};

export const getIsSubscribedToBatch = (batchDetails, batchSkuDetails) => {
  const { batchType, isPrimaryCentreBatch, contentTypeId } = batchDetails;
  const subscribedBatchContentTypes = batchSkuDetails?.metaInfo
    ?.latestUserSkusTypeWise
    ? Object.keys(batchSkuDetails?.metaInfo?.latestUserSkusTypeWise)
    : null;

  if (subscribedBatchContentTypes) {
    const isGoalSubscribed = hasGoalSubscription(
      subscribedBatchContentTypes,
      batchSkuDetails,
      contentTypeId,
      false
    );
    if (isGoalSubscribed) return true;
    if (
      contentTypeId === storeSubscriptionTypes.BATCH &&
      subscribedBatchContentTypes?.indexOf(
        storeSubscriptionTypes.BATCH.toString()
      ) !== -1
    )
      return true;

    if (batchType === batchTypes.CENTRE_BATCH) {
      if (
        subscribedBatchContentTypes.indexOf(
          centreContentTypes.ALL_CENTRE.toString()
        ) !== -1
      ) {
        const hasActiveSubscription = getIsAnyBatchActiveSubscription(
          batchSkuDetails?.metaInfo?.latestUserSkusTypeWise[
            centreContentTypes.ALL_CENTRE.toString()
          ]
        );
        return hasActiveSubscription;
      }
      if (
        subscribedBatchContentTypes.indexOf(
          centreContentTypes.STANDALONE_CENTRE.toString()
        ) !== -1
      ) {
        const hasActiveSubscription = getIsAnyBatchActiveSubscription(
          batchSkuDetails?.metaInfo?.latestUserSkusTypeWise[
            centreContentTypes.STANDALONE_CENTRE.toString()
          ]
        );
        return hasActiveSubscription && isPrimaryCentreBatch;
      }
      return false;
    }

    if (batchType === batchTypes.REGULAR) {
      return hasGoalSubscription(
        subscribedBatchContentTypes,
        batchSkuDetails,
        contentTypeId,
        true
      );
    }
  }
  return false;
};

export const getIsSubscribedToPlus = (goalUID) => (state) => {
  if (!goalUID) {
    return false;
  }
  return getPlusSubscriptionUIDs(state).includes(goalUID.toUpperCase());
};

export const getIsSubscribedToIconic = (goalUID) => (state) => {
  if (!goalUID) {
    return false;
  }
  return getIconicSubscriptionUIDs(state).includes(goalUID.toUpperCase());
};

// adding a change here
export const getIsSubscribedToOffline = (goalUID) => (state) => {
  if (!goalUID) {
    return false;
  }
  return getActiveOfflineSubscriptionUIDs(state).includes(
    goalUID.toUpperCase()
  );
};

export const getIsSubscribedToLite = (goalUID) => (state) => {
  if (!goalUID) {
    return false;
  }
  return getLiteSubscriptionUIDs(state).includes(goalUID.toUpperCase());
};

export const getHasExpiredSubscriptionToGoal = (goalUID) => (state) => {
  if (!goalUID) {
    return false;
  }
  return (getMe(state)?.expiredSubscriptionsUids || []).includes(
    goalUID.toUpperCase()
  );
};
