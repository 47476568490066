import Icon from './Icon';

const Play = (props) => (
  <Icon
    {...props}
    path="M71.725 49.506l-49.679 27.642c-1.698 0.974-3.632 1.498-5.607 1.517-2.013-0-3.988-0.525-5.719-1.517-1.732-0.941-3.175-2.31-4.18-3.968s-1.536-3.543-1.54-5.464v-55.502c0.003-1.921 0.535-3.806 1.54-5.463s2.447-3.026 4.18-3.968c1.705-0.951 3.639-1.452 5.607-1.452s3.902 0.501 5.607 1.452l49.679 27.642c1.78 0.929 3.267 2.306 4.304 3.985s1.584 3.599 1.584 5.554-0.547 3.875-1.584 5.554c-1.036 1.68-2.524 3.056-4.304 3.985h0.112z"
  />
);

export default Play;
