import Icon from './Icon';

const AddedToList = (props) => (
  <Icon
    {...props}
    path="M63.335 25.832h-46.667c-0.663 0-1.299-0.263-1.768-0.732s-0.732-1.105-0.732-1.768c0-0.663 0.263-1.299 0.732-1.768s1.105-0.732 1.768-0.732h46.667c0.663 0 1.299 0.263 1.768 0.732s0.732 1.105 0.732 1.768c0 0.663-0.264 1.299-0.732 1.768s-1.105 0.732-1.768 0.732zM35.001 59.168h-18.333c-0.663 0-1.299-0.264-1.768-0.732s-0.732-1.104-0.732-1.768c0-0.663 0.263-1.299 0.732-1.768s1.105-0.732 1.768-0.732h18.333c0.663 0 1.299 0.263 1.768 0.732s0.732 1.105 0.732 1.768-0.263 1.299-0.732 1.768c-0.469 0.469-1.105 0.732-1.768 0.732zM35.001 42.5h-18.333c-0.663 0-1.299-0.263-1.768-0.732s-0.732-1.104-0.732-1.768c0-0.663 0.263-1.299 0.732-1.768s1.105-0.732 1.768-0.732h18.333c0.663 0 1.299 0.263 1.768 0.732s0.732 1.105 0.732 1.768c0 0.663-0.263 1.299-0.732 1.768s-1.105 0.732-1.768 0.732zM48.998 59.165c-0.328 0.002-0.654-0.062-0.957-0.188s-0.578-0.311-0.809-0.545l-6.667-6.667c-0.246-0.229-0.443-0.505-0.579-0.812s-0.21-0.638-0.216-0.973 0.056-0.669 0.182-0.98c0.126-0.311 0.313-0.594 0.55-0.832s0.52-0.424 0.832-0.55c0.311-0.126 0.645-0.187 0.98-0.182s0.667 0.080 0.974 0.216c0.306 0.136 0.582 0.334 0.811 0.579l5 5 12.667-12.667c0.229-0.246 0.505-0.443 0.812-0.579s0.638-0.21 0.973-0.216c0.336-0.006 0.669 0.056 0.98 0.182s0.594 0.313 0.832 0.55c0.238 0.238 0.424 0.52 0.55 0.832s0.187 0.645 0.182 0.98c-0.006 0.336-0.080 0.667-0.216 0.974s-0.334 0.582-0.58 0.811l-14.433 14.434c-0.251 0.228-0.546 0.402-0.867 0.511s-0.662 0.15-1 0.122z"
  />
);

export default AddedToList;
