import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import styleHelper from '@utils/styleHelper';
import { Fonts, Spacings, breakpoints } from '@styles/index';

const H3 = styled.h3`
  font-weight: ${Fonts.BOLD};
  font-size: ${Fonts.H3};
  line-height: ${Fonts.PARA_LINE_HEIGHT};
  color: ${({ color }) => color};
  margin: ${Spacings.SPACING_0B};
  @media only screen and (max-width: ${breakpoints.tablet}) {
    font-size: 24px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 22px;
  }
  ${({ lineClamp }) =>
    lineClamp ? styleHelper.lineClampStyle(lineClamp) : null}
`;

export default H3;

H3.propTypes = {
  color: PropTypes.string,
  lineClamp: PropTypes.number
};

H3.defaultProps = {
  color: 'var(--color-text-primary)',
  lineClamp: null
};
