export const TAB_STORE_CLICKED = 'Tab - Store Clicked';
export const STORE_TAB_FILTER_APPLIED = 'Store - Tab Filter Applied';
export const STORE_TAB_FILTER_CLICKED = 'Store - Tab Filter Clicked';

export const STORE_FIND_CENTRE_CLICKED = 'Store - Find Centre Clicked';
export const STORE_LANDING_PAGE_VIEWED = 'Store - Landing Page Viewed';

export const storeSubscriptionTypes = {
  BATCH: 500,
  TEST_SERIES: 501,
  NOTES: 600
};

export const storeSubscriptionTypesLabel = {
  500: 'Batch',
  501: 'Test Series',
  600: 'Notes Combo'
};

export const SINGLE_SELECT = 'single_select';
export const ALL = 'All';
