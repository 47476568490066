import styled from '@emotion/styled';
import MUIIconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import { Spacings } from '@styles/index';

const whiteV2 = 'var(--color-base-1)';

const StyledIconButton = styled(MUIIconButton)`
  background-color: ${({ $bgColor }) => {
    return $bgColor || whiteV2;
  }};
  border-radius: ${Spacings.SPACING_2B};
  height: ${Spacings.SPACING_8B};
  width: ${Spacings.SPACING_8B};
  box-shadow: ${boxShadowGenerator()};
  padding: ${Spacings.SPACING_0B};
  &:hover {
    background-color: ${({ $hovDisabled, $bgColor }) => {
      return $hovDisabled ? $bgColor || whiteV2 : 'var(--color-base-1)';
    }};
  }
`;

const IconButton = ({ children, className, onClick, bgColor, hovDisabled }) => (
  <StyledIconButton
    disableRipple
    className={className}
    onClick={onClick}
    $bgColor={bgColor}
    $hovDisabled={hovDisabled}
  >
    {children}
  </StyledIconButton>
);

export default IconButton;

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  hovDisabled: PropTypes.bool
};

IconButton.defaultProps = {
  className: '',
  onClick: () => {},
  bgColor: '',
  hovDisabled: false
};
