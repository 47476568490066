import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import H3 from '@base/H3/H3';
import P1 from '@base/P1/P1';
import Button from '@base/Button/Button';
import { Spacings } from '../../../styleConstants';

// Inner container should be 464px = (560 - (48 + 48)) where 48px is padding
const MsgWrapper = styled.div`
  width: 560px;
  padding: ${Spacings.SPACING_10B} ${Spacings.SPACING_12B};
  text-align: center;
  ${({ styledStringMsgWrapper }) => styledStringMsgWrapper}
  @media (max-width: 47.95em) {
    width: 100%;
    padding: 16px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    &:not(:first-child) {
      margin-left: ${Spacings.SPACING_4B};
    }
  }
`;

const StyP1 = styled(P1)`
  color: ${({ color }) => color || 'var(--color-text-primary)'};
  width: ${({ $width }) => $width};
  margin: ${Spacings.SPACING_2B} auto ${Spacings.SPACING_8B} auto;
`;

const StyH3 = styled(H3)`
  margin-bottom: ${({ isMessagePresent }) =>
    !isMessagePresent ? Spacings.SPACING_8B : '0px'};
`;

const DialogMessage = ({
  type,
  heading,
  message,
  messageWidth,
  messageColor,
  acceptLabel,
  acceptLabelVariant,
  acceptLabelTheme,
  rejectLabel,
  rejectLabelVariant,
  rejectLabelTheme,
  acceptOnClick,
  rejectOnClick,
  showButtons,
  styledStringMsgWrapper,
  children
}) => {
  const getRejectButton = () => (
    <Button
      label={rejectLabel}
      onClick={rejectOnClick}
      size="small"
      variant={rejectLabelVariant}
      color={rejectLabelTheme}
    />
  );

  const getDefaultButton = () => (
    <Button
      label={acceptLabel}
      onClick={acceptOnClick}
      size="small"
      variant={acceptLabelVariant}
      color={acceptLabelTheme}
    />
  );

  const getButtons = () => {
    if (type === 'default') {
      return (
        <>
          {rejectLabel && rejectOnClick && getRejectButton()}
          {acceptLabel && acceptOnClick && getDefaultButton()}
        </>
      );
    }
    if (type === 'destructive') {
      return (
        <>
          {acceptLabel && acceptOnClick && getDefaultButton()}
          {rejectLabel && rejectOnClick && getRejectButton()}
        </>
      );
    }
    if (type === 'informative') {
      return getDefaultButton();
    }
  };
  return (
    <MsgWrapper styledStringMsgWrapper={styledStringMsgWrapper}>
      <StyH3 color="var(--color-text-primary)" isMessagePresent={message}>
        {heading}
      </StyH3>
      {message && (
        <StyP1 color={messageColor} $width={messageWidth}>
          {message}
        </StyP1>
      )}
      {children}
      {showButtons && <ButtonsWrapper>{getButtons()}</ButtonsWrapper>}
    </MsgWrapper>
  );
};
export default DialogMessage;
DialogMessage.propTypes = {
  type: PropTypes.oneOf(['default', 'destructive', 'informative']),
  heading: PropTypes.string.isRequired,
  message: PropTypes.string,
  messageWidth: PropTypes.string,
  messageColor: PropTypes.string,
  acceptLabel: PropTypes.string,
  acceptLabelVariant: PropTypes.oneOf(['hollow', 'filled', 'disabled']),
  acceptLabelTheme: PropTypes.oneOf(['red', 'green', 'black', 'blue']),
  rejectLabel: PropTypes.string,
  rejectLabelVariant: PropTypes.string,
  rejectLabelTheme: PropTypes.string,
  acceptOnClick: PropTypes.func,
  rejectOnClick: PropTypes.func,
  showButtons: PropTypes.bool
};
DialogMessage.defaultProps = {
  type: 'destructive',
  showButtons: true,
  message: null,
  messageWidth: '400px',
  messageColor: 'var(--color-text-primary)',
  acceptLabel: null,
  acceptLabelVariant: 'hollow',
  acceptLabelTheme: 'red',
  rejectLabel: null,
  rejectLabelVariant: 'hollow',
  rejectLabelTheme: 'black',
  acceptOnClick: null,
  rejectOnClick: null
};
