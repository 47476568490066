import Icon from './Icon';

const AddToList = (props) => (
  <Icon
    {...props}
    path="M16.668 25.832h46.667c0.663 0 1.299-0.263 1.768-0.732s0.732-1.105 0.732-1.768c0-0.663-0.264-1.299-0.732-1.768s-1.105-0.732-1.768-0.732h-46.667c-0.663 0-1.299 0.263-1.768 0.732s-0.732 1.105-0.732 1.768c0 0.663 0.263 1.299 0.732 1.768s1.105 0.732 1.768 0.732zM35.001 54.168h-18.333c-0.663 0-1.299 0.263-1.768 0.732s-0.732 1.105-0.732 1.768 0.263 1.299 0.732 1.768c0.469 0.469 1.105 0.732 1.768 0.732h18.333c0.663 0 1.299-0.264 1.768-0.732s0.732-1.104 0.732-1.768c0-0.663-0.263-1.299-0.732-1.768s-1.105-0.732-1.768-0.732zM35.001 37.5h-18.333c-0.663 0-1.299 0.263-1.768 0.732s-0.732 1.105-0.732 1.768c0 0.663 0.263 1.299 0.732 1.768s1.105 0.732 1.768 0.732h18.333c0.663 0 1.299-0.263 1.768-0.732s0.732-1.104 0.732-1.768c0-0.663-0.263-1.299-0.732-1.768s-1.105-0.732-1.768-0.732zM63.335 45.534h-6.133v-6.167c0-0.663-0.264-1.299-0.732-1.768s-1.105-0.732-1.768-0.732c-0.663 0-1.299 0.263-1.768 0.732s-0.732 1.105-0.732 1.768v6.167h-6.167c-0.663 0-1.299 0.263-1.768 0.732s-0.732 1.105-0.732 1.768c0 0.663 0.263 1.299 0.732 1.768s1.105 0.732 1.768 0.732h6.167v6.133c0 0.663 0.263 1.299 0.732 1.768s1.105 0.732 1.768 0.732c0.663 0 1.299-0.264 1.768-0.732s0.732-1.104 0.732-1.768v-6.133h6.133c0.663 0 1.299-0.264 1.768-0.732s0.732-1.105 0.732-1.768c0-0.663-0.264-1.299-0.732-1.768s-1.105-0.732-1.768-0.732z"
  />
);

export default AddToList;
