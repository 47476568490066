import styled from '@emotion/styled';
import MUIPaper from '@material-ui/core/Paper';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import { Spacings } from '@styles/index';

const Paper = styled(MUIPaper)`
  padding: ${({ padding }) => padding || Spacings.SPACING_12B};
  background-color: ${({ bgColor }) => bgColor || 'var(--color-base-1)'};
  box-shadow: ${boxShadowGenerator()};
  grid-column: ${({ gridColumn }) => gridColumn};
  border-radius: 8px;
`;

export default Paper;
