/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Paper from '@base/Paper/Paper';
import H4 from '@base/H4/H4';
import P1 from '@base/P1/P1';
import Button from '@base/Button/Button';
import Thumbnail from '@base/Thumbnail/Thumbnail';
import P2 from '@base/P2/P2';
import Link from '@base/Link/Link';
import Share from '@icon/Share';
import AddToList from '@icon/AddToList';
import AddedToList from '@icon/AddedToList';
import Play from '@icon/Play';
import PlayBig from '@icon/PlayBig';
import {
  dateWithTime,
  getDateShort,
  formatTime,
  getDateMonthString
} from '@utils/dates';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import Dynamic from '@base/Dynamic/Dynamic';
import { itemTypes, programmeTypes } from '@constants/index';
import { Spacings, breakpoints } from '@styles/index';
import Dialog from '@comp/Dialog/Dialog';

const ShareDialog = Dynamic(() =>
  import('../../Composite/ShareDialog/ShareDialog')
);

const Wrapper = styled(Paper)`
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: ${({ $type }) => $type && 'pointer'};
  height: 100%;
  justify-content: ${({ $type }) => !$type && 'space-between'};
  align-items: ${({ $type }) => !$type && 'center'};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding: 0;
    flex-direction: column-reverse;
  }
  width: inherit;
`;

const ExploreButton = styled(H4)`
  border-bottom: 2px dotted;
  border-bottom-color: ${({ $bottomBorderColor }) =>
    $bottomBorderColor || 'var(--color-text-secondary)'};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding-bottom: ${Spacings.SPACING_2B};
  }
`;

const SubTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ $bannerType }) =>
    $bannerType ? Spacings.SPACING_3B : Spacings.SPACING_1B};
  margin-bottom: ${Spacings.SPACING_8B};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin-top: ${Spacings.SPACING_2B};
    margin-bottom: ${Spacings.SPACING_6B};
  }
`;

const ShadowPulse = keyframes`
  0% { box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_0B,
    blur: Spacings.SPACING_0B,
    color: 'rgba(235,87,87, 0.4)'
  })}; }
  70% { box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_0B,
    blur: Spacings.SPACING_0B,
    spread: '10px',
    color: 'rgba(235,87,87, 0)'
  })}; }
  100% {
    box-shadow: none;
  }
`;

const Live = styled.div`
  margin-left: ${Spacings.SPACING_2B};
  margin-right: ${Spacings.SPACING_3B};
  height: 8px;
  width: 8px;
  background-color: var(--color-i-red);
  border-radius: 50%;
  box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_0B,
    blur: Spacings.SPACING_0B,
    color: 'rgba(235,87,87, 0.4)'
  })};
  animation: ${ShadowPulse} 1s infinite;
`;

const ContentWrapper = styled.div`
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  z-index: 1;
  padding: 48px 132px 48px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding: ${Spacings.SPACING_6B} ${Spacings.SPACING_4B};
    width: 100%;
  }
`;

const Image = styled.img`
  position: absolute;
  right: -64px;
  top: 0px;
  height: 100%;
  width: ${({ $imgWidth }) => $imgWidth || '56.6%'};
  object-fit: contain;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    position: relative;
    right: 0;
    width: 100%;
  }
`;

const SubText = styled(P1)`
  color: var(--color-text-primary);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button:first-child {
    margin-right: ${Spacings.SPACING_4B};
  }
  z-index: 1;
`;

const CourseThumbnail = styled(Thumbnail)`
  height: 200px;
  width: 355px;
  border-radius: ${Spacings.SPACING_2B};
`;

const CourseTitle = styled(P2)`
  width: 320px;
  margin-top: ${Spacings.SPACING_6};
`;

const ItemTitle = styled(H4)`
  width: 320px;
  margin-top: ${({ $isSpecialClass }) =>
    $isSpecialClass ? Spacings.SPACING_4B : Spacings.SPACING_3B};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: ${({ $leftOverlay }) => $leftOverlay || 0};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

const InnerWrapper = styled.div`
  z-index: 1;
`;

const Title = styled(H4)`
  @media only screen and (max-width: ${breakpoints.mobile}) {
    line-height: 150%;
  }
`;

const renderOverlay = () => (
  <svg
    width="635"
    height="265"
    viewBox="0 0 635 265"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <g filter="url(#filter0_d)">
        <path d="M0 0H554L494 265H0V0Z" fill="var(--color-base-1)" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-73"
        y="-69"
        width="700"
        height="411"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="36.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0">
        <rect width="635" height="265" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Banner = ({
  course,
  bannerType,
  enroll,
  isUnsigned,
  imgWidth,
  leftOverlay
}) => {
  const {
    isLive,
    isUpcoming,
    name,
    thumbnail,
    startsAt,
    isSpecial,
    courseType,
    isEnrolled,
    relativeLink,
    authors
  } = course;
  const [showShareOptions, setShowShareOptions] = useState(false);
  const nextTrack =
    course.nextTrack?.item && typeof course.nextTrack.item === 'object'
      ? course.nextTrack.item
      : course.nextTrack;
  const previousTrack =
    course.previousTrack?.item && typeof course.previousTrack.item === 'object'
      ? course.previousTrack.item
      : course.previousTrack;
  const authorLink =
    authors?.[0]?.user?.relative_link ||
    authors?.[0]?.user?.relativeLink ||
    authors?.[0]?.relativeLink ||
    '/@legendsonunacademy';

  const onShareClick = (e) => {
    e.stopPropagation();
    setShowShareOptions(true);
  };

  const onKnowMoreClick = (e) => {
    e.stopPropagation();
  };

  const gotoClassPage = (e) => {
    e.stopPropagation();
    const url =
      nextTrack?.relativeLink ||
      nextTrack?.properties?.relative_link ||
      nextTrack?.properties?.relativeLink;
    if (url) window.open(url, '_blank');
  };
  const gotoCoursePage = () => {
    const url = course?.relativeLink;
    if (url) window.open(url, '_blank');
  };
  const renderPrimaryButton = () => {
    let buttonText = 'Enroll';
    let icon = <AddToList size="20px" color="var(--color-base-1)" />;
    const track =
      nextTrack?.isLive || !previousTrack?.uid ? nextTrack : previousTrack;
    let handleClick = (e) => {
      e.stopPropagation();
      enroll?.(course);
    };
    if (track.type === 'post' && !isUpcoming) {
      buttonText = 'Watch now';
      icon = <Play size="16px" color="var(--color-base-1)" />;
      handleClick = (e) => {
        e.stopPropagation();
        window.open(`/class/${track.slug}/${track.uid}`, '_blank');
      };
    }
    if (track.type === itemTypes.QUIZ && !isUpcoming) {
      buttonText = 'Begin test';
      icon = <Play size="16px" color="var(--color-base-1)" />;
      handleClick = (e) => {
        e.stopPropagation();
        window.open(`/quiz/${track.slug}/${track.uid}`, '_blank');
      };
    }
    if (isEnrolled && isUpcoming) {
      buttonText = 'Enrolled';
      icon = <AddedToList size="20px" color="var(--color-text-primary)" />;
      handleClick = () => {};
    }
    return (
      <Button
        grid={4}
        label={buttonText}
        onClick={handleClick}
        variant={isUpcoming && isEnrolled ? 'hollow' : 'filled'}
        color={isUpcoming && isEnrolled ? 'black' : 'green'}
        icon={icon}
      />
    );
  };

  const renderButtons = () => (
    <ButtonsWrapper>
      {bannerType && isLive && !isUpcoming ? (
        <Button
          grid={4}
          label="Watch now"
          onClick={gotoClassPage}
          icon={<PlayBig size="20px" color="var(--color-base-1)" /> || null}
        />
      ) : null}
      {!isUnsigned && !bannerType && renderPrimaryButton()}
      {!isUnsigned ? (
        <Button
          grid={3}
          label="Share"
          onClick={onShareClick}
          color="black"
          icon={<Share size="20px" color="var(--color-text-primary)" />}
          variant="hollow"
        />
      ) : (
        <Link
          href="/@[authorID]"
          as={authorLink}
          onClick={onKnowMoreClick}
          newTab
        >
          <ExploreButton
            $bottomBorderColor="var(--color-i-green)"
            color="var(--color-i-green)"
          >
            Explore more
          </ExploreButton>
        </Link>
      )}
    </ButtonsWrapper>
  );

  const isMissed = (item) => {
    if (item.type === itemTypes.POST && item.hasWatched) return true;
    if (item.type === itemTypes.QUIZ && item.latestSession) return true;
  };

  const getSecondaryText = () => {
    let secondaryText = '';
    if (bannerType) {
      secondaryText =
        isUpcoming && !isLive
          ? `Starts on ${getDateMonthString(startsAt, true)}, ${formatTime(
              startsAt
            )}`
          : `Started at ${formatTime(startsAt)}`;
      if (isLive) secondaryText = `Live  •  ${secondaryText}`;
    } else if (nextTrack?.isLive)
      secondaryText = `Live  •  Started at ${formatTime(nextTrack.startedAt)}`;
    else if (previousTrack.uid) {
      const sessionMissed = isMissed(previousTrack);
      secondaryText = `${previousTrack.type === 'post' ? 'Lesson' : 'Test'} ${
        previousTrack.contentRank || previousTrack.rank
      } · `;
      if (sessionMissed) secondaryText += 'Missed · ';
      secondaryText += `${getDateShort(previousTrack.liveAt)}`;
    } else if (nextTrack) {
      secondaryText = `${nextTrack.type === 'post' ? 'Lesson' : 'Test'} ${
        nextTrack.contentRank || nextTrack.rank
      }`;
      secondaryText += ` · Starts on ${dateWithTime(nextTrack.liveAt)}`;
    }
    return secondaryText;
  };

  const renderContent = () => {
    const isSpecialClass =
      ((courseType === programmeTypes.COURSE ||
        courseType === programmeTypes.ONLINE_WITH_EXTERNAL_STREAM) &&
        isSpecial) ||
      isUpcoming;
    return (
      <>
        <div color="var(--color-text-primary)">
          {!isSpecialClass && (
            <Link
              href={`/${
                courseType === programmeTypes.COURSE ? 'course' : 'test-series'
              }/[slug]/[courseUID]`}
              as={relativeLink}
            >
              <CourseTitle color="var(--color-text-secondary)" lineClamp={1}>
                {name}
              </CourseTitle>
            </Link>
          )}
          <ItemTitle
            color="var(--color-text-primary)"
            $isSpecialClass={isSpecialClass}
            lineClamp={2}
          >
            {isUpcoming
              ? name
              : !nextTrack?.isLive && previousTrack?.name
              ? previousTrack.name
              : nextTrack.name}
          </ItemTitle>
          <SubTextWrapper $bannerType={bannerType}>
            {nextTrack?.isLive ? <Live /> : null}
            <SubText>{getSecondaryText()}</SubText>
          </SubTextWrapper>
          {renderButtons()}
        </div>
        <CourseThumbnail imgUrl={thumbnail} />
      </>
    );
  };
  return (
    <>
      <Wrapper
        padding={bannerType ? '0px' : '32px 48px'}
        onClick={bannerType ? gotoClassPage : gotoCoursePage}
        $type={bannerType}
      >
        {bannerType ? (
          <ContentWrapper type={bannerType}>
            <InnerWrapper>
              <Title color="var(--color-text-primary)" lineClamp={2}>
                {name}
              </Title>
              <SubTextWrapper>
                {isLive ? <Live /> : null}
                <SubText>{getSecondaryText()}</SubText>
              </SubTextWrapper>
            </InnerWrapper>
            {renderButtons()}
            <Overlay $leftOverlay={leftOverlay}>{renderOverlay()}</Overlay>
          </ContentWrapper>
        ) : (
          renderContent()
        )}
        {bannerType ? (
          <Image src={thumbnail} alt={name} $imgWidth={imgWidth} />
        ) : null}
      </Wrapper>
      <Dialog
        onClose={() => setShowShareOptions(false)}
        dialogState={showShareOptions}
      >
        {showShareOptions && <ShareDialog data={course} useDataLink />}
      </Dialog>
    </>
  );
};

export default Banner;

Banner.propTypes = {
  course: PropTypes.objectOf(PropTypes.any).isRequired,
  bannerType: PropTypes.number.isRequired,
  enroll: PropTypes.func,
  isUnsigned: PropTypes.bool,
  imgWidth: PropTypes.string,
  leftOverlay: PropTypes.string
};

Banner.defaultProps = {
  enroll: null,
  isUnsigned: false,
  imgWidth: '56.6%',
  leftOverlay: '0px'
};
