import Icon from './Icon';

const Share = (props) => (
  <Icon
    {...props}
    path="M9 62.333c-1-0.333-1.667-1.667-1.333-2.667 4.333-21.667 17.667-35.667 37.333-39v-3.333c0-1.333 0.667-3 1.667-4 2.333-2.333 6-2.333 8.333-0.333l15.667 15.667c2.333 2.333 2.333 6 0 8.333l-15.667 15.667c-1.667 1.667-4.333 2.333-6.333 1.333-2.333-1-3.667-3-3.667-5.333v-3.333c-15 0.667-25.333 5.667-33 16.333-0.333 0.667-1.333 1-2 1-0.333 0-0.667 0-1-0.333zM47.667 40c1.333 0 2.333 1.333 2.333 2.667v5.667c0 0.333 0.333 0.667 0.667 0.667s0.667 0 1-0.333l15.667-15.667c0 0 0 0 0 0 0.333-0.333 0.333-1 0-1.333l-15.667-15.667c-0.333-0.333-0.667-0.333-1-0.333-0.333 0.667-0.667 1-0.667 1.333v5.333c0 1.333-1 2.333-2 2.333-14.667 1.667-27.333 11.333-32.333 25.333 8-6.667 18.333-10 32-10 0 0 0 0 0 0z"
  />
);

export default Share;
