import Icon from './Icon';

const PlayBig = (props) => (
  <Icon
    {...props}
    path="M64.333 37.833l-46.167-26.667c-0.36-0.204-0.791-0.325-1.25-0.325s-0.89 0.12-1.263 0.332l0.013-0.007c-0.761 0.421-1.267 1.219-1.267 2.136 0 0.011 0 0.022 0 0.033v-0.002 53.333c-0 0.009-0 0.020-0 0.031 0 0.916 0.506 1.714 1.254 2.129l0.012 0.006c0.34 0.21 0.752 0.334 1.193 0.334 0.014 0 0.028-0 0.042-0h-0.002c0.006 0 0.012 0 0.019 0 0.459 0 0.889-0.124 1.259-0.34l-0.012 0.006 46.167-26.667c0.754-0.44 1.253-1.245 1.253-2.167s-0.499-1.727-1.241-2.16l-0.012-0.006z"
  />
);

export default PlayBig;
