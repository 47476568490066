import Icon from './Icon';

const ChevronRight = (props) => (
  <Icon
    {...props}
    path="M27.967 59.8c0-0.003 0-0.008 0-0.012 0-0.687 0.28-1.308 0.733-1.755l18.234-18.234-18.233-18.233c-0.416-0.445-0.671-1.045-0.671-1.704 0-1.381 1.119-2.5 2.5-2.5 0.659 0 1.259 0.255 1.706 0.672l-0.001-0.001 20 20c0.452 0.452 0.731 1.077 0.731 1.767s-0.279 1.314-0.731 1.767v0l-20 20c-0.452 0.452-1.077 0.731-1.767 0.731s-1.314-0.279-1.767-0.731v0c-0.453-0.447-0.733-1.068-0.733-1.755 0-0.004 0-0.008 0-0.012v0.001z"
  />
);

export default ChevronRight;
